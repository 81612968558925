<template>
  <div class="data-page">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="main-content">
      <topContent></topContent>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import topContent from './cpns/top-content/top-content.vue'

export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    topContent
  },
  setup() {


    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.data-page {
  .main-content {
    padding: 20px;
    margin: 20px;
    min-width: 1080px;
    background-color: #fff;
    min-height: 80vh;
  }
}
</style>
