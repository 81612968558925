<template>
	<div v-has="'shop:data:import'">
		<div class="flex">
			<el-upload
			class="btn-padding"
			accept=".xlsx"
			:action="UPLOAD_BASE_URL+'/manager/customer/uploadCustomer'"
			:limit="1"
			ref="uploadRef1"
			:on-success="onSuccess"
			:on-error="onError">
				<el-button type="primary">用户</el-button>
			</el-upload>
			<a href='https://yzg-oss.oss-cn-shanghai.aliyuncs.com/uploadTemplate/yizhige_user.xlsx' download="用户">下载模版文件</a>
		</div>

		<div class="flex">
			<el-upload
			class="btn-padding"
			accept=".xlsx"
			:action="UPLOAD_BASE_URL+'/manager/order/uploadOrder'"
			:limit="1"
			ref="uploadRef2"
			:on-success="onSuccess"
			:on-error="onError">
				<el-button type="primary">订单</el-button>
			</el-upload>
			<a href='https://yzg-oss.oss-cn-shanghai.aliyuncs.com/uploadTemplate/yizhige_order_new.xlsx' download="订单">下载模版文件</a>
		</div>

		<div class="flex">
			<el-upload
			class="btn-padding"
			accept=".xlsx"
			:action="UPLOAD_BASE_URL+'/manager/order/uploadCiKa'"
			:limit="1"
			ref="uploadRef3"
			:on-success="onSuccess"
			:on-error="onError">
				<el-button type="primary">次卡</el-button>
			</el-upload>
			<a href='https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/bf89c784-a35e-4d8c-810b-2781d495b153.xlsx' download="次卡">下载模版文件</a>
		</div>

		<div class="flex">
			<el-upload
			class="btn-padding"
			accept=".xlsx"
			:action="UPLOAD_BASE_URL+'/manager/order/uploadStoredValueCard'"
			:limit="1"
			ref="uploadRef4"
			:on-success="onSuccess"
			:on-error="onError">
				<el-button type="primary">储值卡</el-button>
			</el-upload>
			<a href='https://yzg-oss.oss-cn-shanghai.aliyuncs.com/uploadTemplate/yizhige_chuzhi.xlsx' download="储值卡">下载模版文件</a>
		</div>

		<div class="flex">
			<el-upload
			class="btn-padding"
			accept=".xlsx"
			:action="UPLOAD_BASE_URL+'/manager/money/uploadCustomerCash'"
			:limit="1"
			ref="uploadRef5"
			:on-success="onSuccess"
			:on-error="onError">
				<el-button type="primary">用户余额</el-button>
			</el-upload>
			<a href='https://yzg-oss.oss-cn-shanghai.aliyuncs.com/uploadTemplate/yizhige_yue.xlsx' download="用户余额">下载模版文件</a>
		</div>

		<div class="flex">
			<el-upload
			class="btn-padding"
			accept=".xlsx"
			:action="UPLOAD_BASE_URL+'/manager/order/uploadDiscountCard'"
			:limit="1"
			ref="uploadRef6"
			:on-success="onSuccess"
			:on-error="onError">
				<el-button type="primary">折扣卡</el-button>
			</el-upload>
			<a href='https://yzg-oss.oss-cn-shanghai.aliyuncs.com/uploadTemplate/yizhige_zhekou.xlsx' download="折扣卡">下载模版文件</a>
		</div>

		<div class="flex">
			<el-upload
			class="btn-padding"
			accept=".xlsx"
			:action="UPLOAD_BASE_URL+'/manager/order/uploadComment'"
			:limit="1"
			ref="uploadRef7"
			:on-success="onSuccess"
			:on-error="onError">
				<el-button type="primary">评论</el-button>
			</el-upload>
			<a href='https://yzg-oss.oss-cn-shanghai.aliyuncs.com/uploadTemplate/yizhige_comment.xlsx' download="评论">下载模版文件</a>
		</div>
		<p style="color: #f30">注意：</p>
		<p style="color: #f30">excel表下方的sheet名字不能修改</p>
	</div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { UPLOAD_BASE_URL } from '@/common/constant';
import { tips } from '@/utils/tips'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
	const uploadRef1 = ref(null)
	const uploadRef2 = ref(null)
	const uploadRef3 = ref(null)
	const uploadRef4 = ref(null)
	const uploadRef5 = ref(null)
	const uploadRef6 = ref(null)
	const uploadRef7 = ref(null)

	const onSuccess = (res, file, fileList) => {
		// console.log('success');
		// console.log(res.msg);
		clear()
		tips(res);
	}

	const onDownload = (url) => {
		window.location.href = url
	}

	const clear = () => {
		uploadRef1.value.clearFiles()
		uploadRef2.value.clearFiles()
		uploadRef3.value.clearFiles()
		uploadRef4.value.clearFiles()
		uploadRef5.value.clearFiles()
		uploadRef6.value.clearFiles()
		uploadRef7.value.clearFiles()
	}

	const onError = (err, file, fileList) => {
		// console.log('error');
		clear()
		tips(err);
	}

    return {
		uploadRef1,
		uploadRef2,
		uploadRef3,
		uploadRef4,
		uploadRef5,
		uploadRef6,
		uploadRef7,
		UPLOAD_BASE_URL,
		onDownload,
		onSuccess,
		onError,
    }
  }
})
</script>

<style scoped lang="less">
.flex {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}
.btn-padding {
	margin-right: 12px;
}
&:deep(.el-button) {
  span {
    display: block;
    width: 100%;
    height: 100%;

    a {
      text-decoration: none;
      color: white;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
